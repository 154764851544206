import { get, trim } from "lodash";
import { api } from "../app/api";
import { createContext, useEffect, useState } from "react";

const getLanInHref = (href) => {
  const urlObject = new URL(href);
  if (urlObject.searchParams.has("lan")) {
    return urlObject.searchParams.get("lan");
  } else return undefined;
};

export const LanguageContext = createContext({});
function LanguageProvider({ children }) {
  const [languagesKeyVal, setlanguagesKeyVal] = useState({});

  const langCode = getLanInHref(window.location.href) || window.location.pathname.substring(1, 3);

  const getLangDatas = async () => {
    try {
      const langsdata = await api.get(`language/get-key-value/${langCode}`);
      setlanguagesKeyVal(langsdata.data);
    } catch (e) {}
  };
  const t = (key) => {
    return get(languagesKeyVal, trim(key)) || key;
  };

  useEffect(() => {
    getLangDatas();
  }, []);

  return <LanguageContext.Provider value={{ languagesKeyVal, t }}>{children}</LanguageContext.Provider>;
}

export default LanguageProvider;
