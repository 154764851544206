import MainLayout from "containers/MainLayout";
import { lazy } from "react";
import { Navigate, RouterProvider, createBrowserRouter, useRoutes } from "react-router-dom";
import Loadable from "../components/Loadable";

const Main = Loadable(lazy(() => import("../pages/main/Main")));
const PageNotFound = Loadable(lazy(() => import("../pages/404/PageNotFound")));
const Confirmation = Loadable(lazy(() => import("../pages/confirmation/confirmation")));
const InformationPage = Loadable(lazy(() => import("../pages/information/InformationPage")));
const DefaultPage = Loadable(lazy(() => import("../pages/default/DefaultPage")));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "",
        element: <DefaultPage />,
      },
      {
        path: ":lan/:hotelId",
        element: <Main />,
      },
      {
        path: ":lan/:hotelId/reservation",
        element: <InformationPage />,
      },
      {
        path: ":lan/:hotelId/:targetCategoryId",
        element: <Main />,
      },
      {
        path: ":lan/:hotelId/confirmation/:orderId",
        element: <Confirmation />,
      },
    ],
  },
  // {
  //   path: "*",
  //   element: <Navigate to="/404" />,
  // },
]);

export default router;
