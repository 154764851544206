import { useEffect, useState } from "react";
// import MainRoutes from "./app/routes";
import Theme from "./theme/theme";
import { RouterProvider } from "react-router-dom";
import router from "./app/routes";
export const PARENT_ORIGIN = "*";

function App() {
  const [height, setHeight] = useState(0);

  // Listen for changes to the document body height.
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });
    observer.observe(document.querySelector("body"));

    return () => {
      observer.disconnect();
    };
  }, []);

  // Send a post message to the parent window with the current document body height.
  useEffect(() => {
    document.documentElement.style.overflow = +height > 320 ? "unset" : "hidden";
    window.parent.postMessage(height, PARENT_ORIGIN);
  }, [height]);
  return (
    <Theme>
      <RouterProvider router={router} />
    </Theme>
  );
}

export default App;
