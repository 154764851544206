export const defaultDesingItems = {
  background: 'url("http://testb.roomster.com.ua/api/v1/attachment/download/46d5236d-3d52-4fdb-88ef-4d5923ae75e7.png") transparent no-repeat bottom center/cover',
  colors: {
    primary: "#ffc329",
    secondary: "#e8e7f1",
    text: "#2d2d4b",
    outside: "#2d2d4b",
    button: "#6f581d",
  },
  bookBtn: {
    background: "white",
    radius: "42",
    color: "#2d2d4b",
    icon: "#2d2d4b",
    border: true,
  },
  payBtn: {
    background: "#2d2d4b",
    radius: "42",
    color: "#ffffff",
    icon: "#ffffff",
    border: false,
  },
  recommend: {
    color: "#ffffff",
    background: "#000000",
  },
  lastImg: "http://testb.roomster.com.ua/api/v1/attachment/download/64603da6-245b-4089-ba07-301272655ac5.png",
};
