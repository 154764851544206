import { createSlice } from "@reduxjs/toolkit";

const defaultNomer = { people: 1, children: 0 };

const initialState = {
  dateRange: {},
  nomers: [defaultNomer],
  peopleCount: 1,
  childrenCount: 0,
  targetCategoryId: null,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    changeFilterData: (state, action) => ({ ...state, ...action.payload }),
    changeDate: (state, action) => ({ ...state, dateRange: action.payload }),
    changeNomers: (state, action) => ({ ...state, nomers: action.payload }),
    addNomer: (state, action) => ({
      ...state,
      peopleCount: state.peopleCount + defaultNomer.people,
      nomers: [...state.nomers, defaultNomer],
    }),
    editNomer: (state, action) => {
      const nomers = [...state.nomers];
      if (!action.payload.value.people && nomers.length === 1) return { ...state };
      else if (!action.payload.value.people) nomers.splice(action.payload.index, 1);
      else nomers[action.payload.index] = action.payload.value;

      const peopleCount = nomers.reduce((acc, item) => acc + item.people, 0);
      const childrenCount = nomers.reduce((acc, item) => acc + item.children, 0);

      return { ...state, nomers, childrenCount, peopleCount };
    },
    setTargetCategory: (state, action) => ({
      ...state,
      targetCategoryId: action.payload,
    }),
  },
});

export const { editNomer, addNomer, changeFilterData, changeNomers, changeDate, setTargetCategory } = filterSlice.actions;

export default filterSlice.reducer;
