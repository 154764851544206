import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeRate: null,
  total_price: null,
  prepaymentPrice: 0,
  msg: "",
  hasPrepayment: false,
};

export const rateSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {
    setRate: (state, action) => ({ ...state, activeRate: action.payload }),
    clearRate: () => initialState,
    changeRateField: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { changeRateField, setRate, setPrice, clearRate } = rateSlice.actions;

export default rateSlice.reducer;
