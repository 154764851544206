import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./assets/scss/app.scss";
import App from "./App";
import LanguageProvider from "./components/language";

window.React = React;

// for create bundle js
// const root = ReactDOM.createRoot(document.getElementById("hotel-booking"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </Provider>
);
reportWebVitals();
