import { get } from "lodash";
import { defaultDesingItems as dD } from "utils/constants";
const { default: styled } = require("@emotion/styled");

const GlobalStyled = styled.div`
  background: ${(props) => get(props, "style.background", dD.background)};
  min-height: 155px;
  color: ${(props) => get(props, "style.colors.text", dD.colors.text)};
  .outside,
  .outside svg {
    color: ${(props) => get(props, "style.colors.outside", dD.colors.outside)} !important;
  }
`;

export default GlobalStyled;
