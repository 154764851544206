import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

export const styleSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {
    setStyle: (state, action) => action.payload || {},
    clearStyle: () => initialState,
  },
});

export const { setStyle, clearStyle } = styleSlice.actions;

export default styleSlice.reducer;
