import { styleSelector } from "app/selectors";
import GlobalStyled from "assets/scss/globalStyled";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  const style = useSelector(styleSelector);

  return (
    <GlobalStyled style={style} id="global_styled">
      <Outlet />
    </GlobalStyled>
  );
};

export default MainLayout;
