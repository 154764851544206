import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#493E8F" },
    secondary: { main: "#FFC329" },
    font: { main: "#2D2D4B" },
    warning: { main: "#EA1958" },
    success: { main: "#4CCEB7" },
  },
  typography: {
    fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1200,
      xxl: 1320,
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
