import axios from "axios";
import config from "./../config";
import Swal from "sweetalert2";
import { isEqual } from "lodash";

export const api = axios.create({
  baseURL: config.API_ROOT,
});
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

api.interceptors.request.use(
  function (config) {
    config.headers.TimeZone = timezone;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.data?.success && response.data?.data) {
      response.data = response.data.data;
    }
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export const request = async ({ url, attributes = null, method = "get", success = () => "", fail = () => "" }) => {
  try {
    let response = {};
    if (isEqual(method, "patch")) {
      response = await api.patch(url, attributes);
    } else if (isEqual(method, "put")) {
      response = await api.put(url, attributes);
    } else if (isEqual(method, "delete")) {
      response = await api.delete(url, { data: attributes });
    } else if (isEqual(method, "post")) {
      response = await api.post(url, attributes);
    } else {
      response = await api.get(url);
    }

    success(response);
  } catch ({ response }) {
    fail(response);
  }
};

export const handleError = (error) => {
  if (error?.response?.data?.errors) {
    Swal.fire({
      icon: "error",
      title: "Error List",
      html: error.response.data.errors.reduce((acc, err, index) => `${acc} <p style="margin: 0 0 10px 20px; display: flex">${index + 1}) ${err.errorMsg}</p>`, ""),
    });
  } else if (error?.data?.errors) {
    Swal.fire({
      icon: "error",
      title: "Error List",
      html: error.data.errors.reduce((acc, err, index) => `${acc} <p style="margin: 0 0 10px 20px; display: flex">${index + 1}) ${err.errorMsg}</p>`, ""),
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error?.response?.data?.error || error.message,
    });
  }
};
